import { ClientAuthService } from "@/api/instance";

export const ResetPassword = async (password, token) => {
  await ClientAuthService.post(
    "/reset-password",
    { password },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};
