<template>
  <div class="reset-password-container">
    <h1 id="reset-password-title">
      Reset Password
    </h1>
    <v-form ref="form" @submit.prevent="submit()">
      <p class="input-label">Password <span>*</span></p>
      <p class="input-hint">- Must be at least 8 characters</p>
      <v-text-field
        name="password"
        v-model="newPassword"
        type="password"
        outlined
        prepend-inner-icon="lock"
        :rules="[rules.password]"
      ></v-text-field>
      <p class="input-label">Confirm new password <span>*</span></p>
      <p class="input-hint">- Must match previous password</p>
      <v-text-field
        name="confirm_password"
        v-model="confirmNewPassword"
        type="password"
        outlined
        prepend-inner-icon="lock"
        :rules="[rules.confirmPassword]"
      ></v-text-field>
      <v-btn class="submit-btn" color="primary" type="submit">Submit</v-btn>
    </v-form>
  </div>
</template>

<script>
import { ResetPassword } from "@/api/auth/reset-password";
export default {
  data() {
    return {
      rules: {
        confirmPassword: v =>
          v === this.newPassword || "Both Passwords must match",
        password: value => value.length >= 8 || "Password is too short"
      },
      newPassword: "",
      confirmNewPassword: ""
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.newPassword === this.confirmNewPassword) {
          await ResetPassword(this.newPassword, this.$route.query.token);
          this.$router.push("/auth/login");
        }
      }
    }
  }
};
</script>

<style scoped>
.reset-password-container {
  max-width: 650px;
  margin: 0 auto;
}
#reset-password-title {
  font-weight: 400;
  text-align: center;
  margin-bottom: 60px;
  color: var(--v-grey-base);
}
.input-label {
  font-size: 22px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
.input-hint {
  color: var(--v-grey-lighten1);
}
.submit-btn {
  float: right;
}
@media only screen and (max-width: 767px) {
  #reset-password-title {
    font-size: 22px !important;
    margin-bottom: 10px;
  }
  .input-label {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .forgot-password p {
    font-size: 18px;
  }
  ::v-deep .v-btn__content {
    font-size: 18px;
  }
}
</style>
